:root {
    --theme-bg: #b3b2d9;
    --theme-bg-2: #9292d6;
    --theme-text: #5050cc;
}

.deblink-logo {
    border-bottom: 2px solid var(--theme-text);
    margin-left: 20px;
    margin-right: 20px;
}

.header-text {
    color: var(--theme-text);
    font-weight: 800;
    font-size: 11px;
}

:is(.dark .header-text) {
    color:var(--theme-bg);
}

.header-subtext {
    font-size: 10px;
}

.header-line {
    border-right: 2px solid var(--theme-text);
    margin-right: 20px;
}

:is(.dark .header-line) {
    border-right: 2px solid var(--theme-bg);
}


.custom-table-data .table-default thead>tr>th {
    background-color: #3b82f6;
    color: red;
}

table.model-code-r-table {
    border: 1px solid #b9b9b9;
}

.model-code-r-table .th-top {
    background-color: #4472c4;
    color: white;
    padding: 8px 0;
}

.model-code-r-table .th-btm {
    background-color: #d9e1f2;
    color: #4472c4;
    font-weight: 600;
    padding: 8px 4px;
    text-align: center;
    border: 1px solid #b9b9b9;
    border-bottom: none;
}

.model-code-r-table .th-btm:first-child {
    border-left: none;
}

.model-code-r-table .th-btm:last-child {
    border-right: none;
}

.model-code-r-table .td-data {
    color: #4472c4;
    padding: 8px;
    border: 1px solid #b9b9b9;
}

.model-code-r-table .td-data:first-child {
    border-left: none;
}
.modal_65_vh {
    max-height: 65vh;
    min-height: 30vh;
    padding-right: 1em;
    overflow-y: scroll;
}
.model-code-r-table .td-data:last-child {
    border-right: none;
}

.model-code-r-table .tr-data .th-btm {
    background-color: white;
}

.parentDiv {
    border: 1px solid rgba(0,0,0,0.2);
}

.flex-child.sm-child {
    width: 20%;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.flex-child.lg-child {
    width: 80%;
    padding: 0 8px;
    text-align: center;
}

.flexFirst {
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.overflow-y-enable .dialog-content {
    overflow-y: scroll;
}

.text-center-important {
    text-align: center !important;
}

.customImage {
    height: 6rem;
    max-width: max-content;
    object-fit: cover;
    width: 100%;
}

.height80vh {
    height: 80vh;
}

.sticky-recommend {
    position: sticky;
    top: -1.5rem;
    background: white;
    z-index: 9;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 16px;
    border-radius: 6px;
}

.spk-rtw-form .form-item {
    margin-bottom: 1rem;
}

.product-image-sm {
    height: 240px;
}

@media print {
    body div{
      display: none;
    }
  
  
    body div.print-sku {
      display: block !important;
      height: 20mm;
      width: 82mm;
    }
    
    body div.break-print {
      display: flex !important;
      flex-wrap: nowrap;
      height: 100%;
      width: 100%;
    }
    
    body div.print-sku > div {
      display: flex !important;
    }
    
    .break-print {
      page-break-after: always;
    }
    
    .qrcode {
      display: block;
    }
    
    body div.break-print {
      display: flex;
      flex-wrap: nowrap;
      height: 100%;
      width: 100%;
    }
      
    body div.print-sku div {
      display: flex;
    }
    
    .break-print {
      page-break-after: always;
    }
    
    .qrcode {
      display: block;
    }
    
    .qrtext {
      border: 1px solid black;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
      color: black;
      font-size: 6px;
      font-weight: bolder;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .middletext {
      font-size: 7px !important;
    }
    
    .middletextmicro {
      font-size: 6px !important;
    }
    
    .noleft {
      border-left: none !important;
    }
    
    .noright {
      border-right: none !important;
    }
    
    .notop {
      border-top: none !important;
    }
    
    .nobottom {
      border-bottom: none !important;
    }
  
    .rtw-front .qrtext,
    .rtw-back .qrtext {
      border: 0;
    }
  
    .rtw-back .top,
    .rtw-front .flex {
      /* border: 0; */
      /* border-bottom: 1px solid black; */
      width: 100%;
    }
  
    .rtw-front .flex:last-child {
      border: 0;
      width: 100%;
    }
  
    .rtw-back .top .flex {
      justify-content: space-between;
    }
  
    .rtw-stone-micro p,
    table.rtw-table td {
      font-size: 6px;
      font-weight: bolder;
      color: black;
    }
    
    .block-important {
      display: block !important;
    }
    
    .leftSide {
      width: 45%;
      margin-left: -7px;
    }
    
    .rightSide {
      width: 50%;
      margin-left: -11px;
    }
    
    .rightLabel {
      margin-left: 2px !important;
    }
  
  
  }